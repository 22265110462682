import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";

const Hero = ({ scrollToRef }) => {
    return (
        <div className={styles.hero} style={{ backgroundImage: `url('./images/precontent/header.webp')` }}>
            <div className={cn("container", styles.container)}>
                <div className={styles.wrap}>
                    <h2 className={cn("h2", styles.title)}>
                        We are not Poor. We are Pre-Rich.
                    </h2>
                    <div className={styles.text}>
                        A Super Hero who goes to any extent for his community to become Ultra Rich
                    </div>
                    <div className={styles.btns}>
                        <Link
                            className={cn("button", styles.button)}
                            to="https://app.uniswap.org/swap?outputCurrency=0xd716d7ff2436769f3e78c77735cfbf405736f9c0"
                            target="_blank"
                        >
                            Buy $PRE on Uniswap
                        </Link>
                    </div>
                </div>
                <ScrollButton
                    onScroll={() =>
                        scrollToRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                    className={styles.scroll}
                />
                {/* <div className={styles.gallery}>
                    <div className={styles.preview}>
                        <Image
                            srcSet="/images/precontent/header.png 2x"
                            srcSetDark="/images/precontent/header.png 2x"
                            src="/images/precontent/header.png"
                            srcDark="/images/precontent/header.png"
                            alt="Watch"
                        />
                    </div>
                    <div className={styles.preview}>
                        <img
                            srcSet="/images/precontent/pepe.png 2x"
                            src="/images/precontent/pepe.png"
                            alt="Ball"
                        />
                    </div>
                    <div className={styles.preview}>
                        <img
                            srcSet="/images/content/bottle@2x.png 2x"
                            src="/images/content/bottle.png"
                            alt="Bottle"
                        />
                    </div>
                    <div className={styles.preview}>
                        <img
                            srcSet="/images/content/ball-black@2x.png 2x"
                            src="/images/content/ball-black.png"
                            alt="Ball black"
                        />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Hero;
