import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Offer.module.sass";

const Offer = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          From HODL to Gold
        </div>
        <h2 className={cn("h1", styles.title)}>
          The Bull Run Beckons. Are you ready?
        </h2>
        <div className={styles.text}>
          Flip the Script on the Market: With PRE-RICH, you're not just riding the waves—you're waiting to surf the tsunami of the next bull run.
        </div>
        {/* <Link className={cn("button", styles.button)} to="/download">
          Get your PRE-RICH
        </Link> */}
      </div>
    </div>
  );
};

export default Offer;
