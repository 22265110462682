import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../Icon";

const Item = ({ item }) => {
  return (
    <div className={styles.item}>
      <div className={styles.preview}>
            <img
              srcSet={`${item.image2x} 2x`}
              src={item.image}
            />
          </div>
    </div>
  );
};

export default Item;
