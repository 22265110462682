import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Subscription from "../Subscription";
import Theme from "../Theme";
import Icon from "../Icon";
import Image from "../Image";

const menu = [
    {
        title: "Lifestyle",
        url: "/lifestyle",
    },
    {
        title: "Pricing",
        url: "/pricing",
    },
    {
        title: "Class",
        url: "/class01",
    },
    {
        title: "Features",
        url: "/features",
    },
    {
        title: "Download",
        url: "/download",
    },
];

const socials = [
    {
        title: "twitter",
        size: "18",
        url: "https://twitter.com/ui8",
    },
    {
        title: "instagram",
        size: "16",
        url: "https://www.instagram.com/ui8net/",
    },
    {
        title: "telegram",
        size: "16",
        url: "https://www.instagram.com/ui8net/",
    },
];

const Footer = () => {
    const [visible, setVisible] = useState(false);

    const { pathname } = useLocation();

    return (
        <footer className={styles.footer}>
            <div className={styles.foot}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.box}>
                        <Link className={styles.logo} to="/">
                            <Image
                                className={styles.pic}
                                src="/images/precontent/logo.png"
                                srcDark="/images/precontent/logo.png"
                                alt="Logo"
                            />
                        </Link>
                        {/* <Theme className={styles.theme} /> */}
                    </div>
                    <div className={styles.copyright}>
                        Copyright © 2023. All rights reserved
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
