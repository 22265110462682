import React from "react";
import cn from "classnames";
import styles from "./Community.module.sass";
import Subscription from "../Subscription";
import Image from "../Image";
import { Link } from "react-router-dom";

const Community = () => {
  return (
    <div className={cn("section-border-top", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.preview}>
          <Image
            srcSet="/images/precontent/community.png 2x"
            srcSetDark="/images/precontent/community.png 2x"
            src="/images/precontent/community.png"
            srcDark="/images/precontent/community.png"
            alt="Community"
          />
        </div>
        <div className={cn("stage-small", styles.stage)}>
          Become a Pre-Rich
        </div>
        <h2 className={cn("h2", styles.title)}>Get in the Circle</h2>
        <div className={styles.text}>
            Tap in! Slide into our Telegram and hit follow on Twitter for all the alpha on Pre-Rich. This is the fam where we chart the course to moon, together. Don't just watch the hype, be the hype.
        </div>
        <div className={styles.btns}>
            <Link
                className={cn("button-stroke", styles.button)}
                to="https://twitter.com/PreRichProject"
                target="_blank"
            >
                Telegram
            </Link>
            <Link
                className={cn("button-stroke", styles.button)}
                to="https://t.me/prericherc20"
                target="_blank"
            >
                Twitter
            </Link>
        </div>
      </div>
    </div>
  );
};

export default Community;
