import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Join & Share",
    color: "#3772FF",
    images: "/images/content/whistle.svg",
    content:
      "Join Pre-Rich Telegram & Twitter and share to your network.",
  },
  {
    title: "Create Memes",
    color: "#9757D7",
    images: "/images/content/download.svg",
    content:
      "Create Pre-Rich community memes and share on Twitter.",
  },
  {
    title: "Submit",
    color: "#EF466F",
    images: "/images/content/stopwatch.svg",
    content:
      "Submit your content to our team for review.",
  },
  {
    title: "Review and Reward",
    color: "#45B26B",
    images: "/images/content/medal.svg",
    content:
      "Pre-Rich team will review for uniqueness and get your NFT reward",
  },
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>Meme-to-Earn</h2>
          <div className={styles.info}>
            Out of the the 100 Governance NFTs, 30 will be rewarded to the most active members of the community.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                <img src={x.images} alt={`Step ${index}`} />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
